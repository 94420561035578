:root {
    --redPurple: rgba(95, 8, 75, 1);
    --darkPurple: rgba(71, 34, 85, 1);
    --darkBlue: rgba(47, 60, 94, 1);
    --darkTeal: rgba(23, 85, 103, 1);
    --lightTeal: rgba(0, 111, 113, 1);
    --smallCaps: 'Alegreya SC', serif;
    --MonoFont: 'Syne Mono', monospace;
    --SansFont: 'Advent Pro', sans-serif;
  }
  
  /*--- about me styling ---*/
#aboutMe {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
  }
  
  .bioImg {
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    flex-basis: 40%;
    height: 100%;
    margin: 0;
    background-image: 
    linear-gradient(
      rgba(63,63,63, 0.7),
      rgba(63,63,63, 0.7)
    ), 
    url('../images/filler.avif');
    background-size: cover;
  }
  
  .bioContainer {
    flex: 1;
    flex-grow: 1;
    flex-basis: 100%;
    background-color: rgba(255,255,255, 0.4);
    margin-bottom: 100px;
  }
  
  .bioContainer h1 {
    font-weight: normal;
    padding: 0;
    margin: 10px 0 0 0;
    font-family: var(--SansFont);
    font-size: 4em;
    color: #eeeeee;
  }
  
  .bioContainer h2 {
    font-weight: normal;
    padding-top: 0;
    font-family: var(--smallCaps);
    font-size: 1em;
    color: #eeeeee;
  }
  
  /*--- skills and text content of about me ---*/
  /*--- outer container ---*/
  .bioContent {
    flex: 1;
    flex-grow: 1;
    flex-basis: 50%;
    height: 100%;
    overflow: auto;
    display: inline-block;
    color: #000;
    background-color: rgba( 255, 255, 255, 0.7);
    font-family: var(--SansFont);
  }
  
  .bioContent H1 {
    font-family: var(--smallCaps);
    font-size: 40px;
    color: var(--darkPurple);
  }
  .bioContent H2 {
    font-family: var(--SansFont);
    font-size: 20px;
    color: var(--darkBlue);
  }
  .bioContent H3 {
    font-family: var(--MonoFont);
    font-weight: normal;
    font-size: 25px;
    color: var(--lightTeal);
  }
  
  /*--- individual sections ---*/
  #section1 {
    padding: 200px 0 0 0;
  }
  
  #section1, #section2, #section3 {
    text-align: center;
    margin: 50px 0;
  }
  
  #section1 p, #section2 p, #section3 p {
    text-align: justify;
    margin: 0 5%;
    padding: 30px;
    font-size: 1em;
    border: 1px solid var(--darkPurple);
  }
  
  #section2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }
  
  #section2 .skills {
    flex: 1;
    flex-grow: 1;
    flex-basis: 40%;
  }
  
  .skills ul {
    text-align: left;
  }

/* Tablets */
@media screen and (max-width: 992px) {
  #aboutMe {
    flex-basis: 100%;
    height: fit-content;
  }
  .bioImg {
    flex-basis: 100%;
    height: 70vh;
    margin-top: 50px;
  }
  #section1 {
    padding: 50px 0;
  }
}

/* Large smartphones */
@media screen and (max-width: 769px) {
  #aboutMe {
    flex-basis: 100%;
    height: fit-content;
  }
  .bioImg {
    flex-basis: 100%;
    height: 75vh;
    margin-top: 50px;
  }
  #section1 {
    padding: 50px 0;
  }
}

/* Small smartphones */
@media screen and (max-width: 576px) {
  #aboutMe {
    flex-basis: 100%;
    height: fit-content;
  }
  .bioImg {
    flex-basis: 100%;
    margin-top: 50px;
  }
  .bioContainer {
    margin: 100px 0;
  }
  #section1 {
    padding: 50px 0;
  }
}