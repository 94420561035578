:root {
  --redPurple: rgba(95, 8, 75, 1);
  --darkPurple: rgba(71, 34, 85, 1);
  --darkBlue: rgba(47, 60, 94, 1);
  --darkTeal: rgba(23, 85, 103, 1);
  --lightTeal: rgba(0, 111, 113, 1);
  --smallCaps: 'Alegreya SC', serif;
  --MonoFont: 'Syne Mono', monospace;
  --SansFont: 'Advent Pro', sans-serif;
}

/*--- navigation bar styling ---*/
#nav {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 95%;
    margin: 1%;
    background-color: var(--redPurple);
    padding: 10px 20px;
    border-radius: 30px;
  }
  .nav1 {
    width: 30%;
    display: inline-block;
  }
  .nav2 {
    width: 65%;
    display: inline-block;
    text-align: right;
  }
  
  #nav li {
    display: inline-block;
  }
  
  #navName {
    font-family: var(--smallCaps);
    font-size: 30px;
    padding-left: 7%;
    color: #eeeeee;
  }
  
  #nav .inactive {
    color: #eeeeee;
    font-family: var(--MonoFont);
    padding: 0 20px;
  }
  
  #nav .inactive:hover {
    color: var(--lightTeal);
    text-shadow: 0 0 5px #000;
    transform: scale(1.1);
    outline: 1px solid transparent;
  }

  #nav .active {
    color: #747474;
    font-family: var(--MonoFont);
    padding: 0 20px;
  }
  
/* Small smartphones */
@media screen and (max-width: 576px) {
  #nav {
    width: 100%;
    border-radius: 0;
    margin: 0;
    padding: 5px;
  }
  #navName {
    font-size: 20px;
  }
}