/*--- footer styling ---*/
#footer {
    background-color: var(--darkBlue);
    padding: 20px 10px;
    font-family: var(--MonoFont);
    text-align: center;
    color: #eeeeee;
  }
  
  #footer a {
    text-decoration: none;
    height: fit-content;
    padding: 0;
    margin: 0 10px;
  }
  
  #footer h3 {
    font-size: 20px;
    width: fit-content;
    margin: auto;
    border-bottom: 1px solid var(--lightTeal);
  }
  
  #footer h5 {
    margin: 0;
    font-size: 15px;
    display: inline-block;
    font-weight: normal;
  }
  
  .footerContact {
    font-size: 40px;
    margin: 10px;
    display: inline-block;
    color: #eeeeee;
  }
  
  .footerContact i {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 30px;
  }
  
  .footerContact:hover {
    color: var(--lightTeal);
    text-shadow: 0 0 5px #000;
    transform: scale(1.1);
    outline: 1px solid transparent;
  }