:root {
    --redPurple: rgba(95, 8, 75, 1);
    --darkPurple: rgba(71, 34, 85, 1);
    --darkBlue: rgba(47, 60, 94, 1);
    --darkTeal: rgba(23, 85, 103, 1);
    --lightTeal: rgba(0, 111, 113, 1);
    --smallCaps: 'Alegreya SC', serif;
    --MonoFont: 'Syne Mono', monospace;
    --SansFont: 'Advent Pro', sans-serif;
  }

  #portfolio {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    padding-top: 98px;
    min-height: 77vh;
  }

  #portfolio .card {
    flex: 1;
    flex-grow: 1;
    flex-basis: 30%;
    height: 260px;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 10px;
    padding: 10px;
    text-align: center;
  }

  .card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    position: relative;
    z-index: 999;
  }

  .card a {
    text-decoration: none;
    color: var(--lightTeal);
    font-family: var(--SansFont);
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin: 10px;
  }

  .card h4 {
    font-family: var(--smallCaps);
    font-weight: normal;
    text-align: center;
    font-size: 25px;
    margin: 0;
    color: #eeeeee;
    background-color: var(--darkTeal);
  }

  .cardHover {
    margin-top: -228px;
    position: relative;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.9);
    height: 180px;
    padding: 10px;
    overflow: auto;
    text-align: justify;
    font-family: var(--SansFont);
    opacity: 0;
  }

  .cardHover h6 {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #000;
    margin: 0 0 10px 0;
    padding: 5px 0;
  }

  .cardHover:hover {
    opacity: 1;
    transition: opacity 0.7s;
  }

/* Small smartphones */
@media screen and (max-width: 576px) {
  #portfolio .card {
    flex-basis: 90%;
    height: 260px;
  }
  .cardHover {
    cursor: pointer;
  }
}